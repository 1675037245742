import { createMuiTheme } from '@material-ui/core/styles';

/**
 * Convert hex code to rgb values
 * Found here https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
 */
const hexToRgb = (hex: string) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

/**
 * Utility function for when figma designs have `pass through`
 * attached to colors. It adds opacity but doesn't assume it effects
 * rest of children elements
 */
const addPassThrough = (hex: string, opacity: number) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { r, g, b } = hexToRgb(hex)!;
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const GRAYS = {
  100: 'hsl(210, 14%, 89%)',
  300: 'hsl(0, 0%, 80%)',
  500: 'hsl(0, 0%, 67%)',
  700: 'hsl(202, 6%, 37%)',
  900: 'hsl(210, 9%, 31%)',
};

export const PRIMARY_HUE = '208, 46%, 54%';
export const SECONDARY_HUE = '358, 68%, 50%';

const PRIMARY_COLORS = {
  100: 'hsl(208, 73%, 78%)',
  300: 'hsl(208, 65%, 65%)',
  500: 'hsl(208, 46%, 54%)',
  700: 'hsl(208, 46%, 44%)',
  900: 'hsl(208, 61%, 38%)',
};

const SECONDARY_COLORS = {
  100: 'hsl(358, 94%, 71%)',
  200: 'hsl(358, 98%, 57%)',
  300: 'hsl(358, 93%, 57%)',
  500: 'hsl(358, 68%, 50%)',
  700: 'hsl(358, 69%, 40%)',
  900: 'hsl(358, 66%, 27%)',
};

const OFF_WHITE_COLORS = {
  100: 'hsl(0,0,99%)',
  300: 'hsl(0, 0%, 98%)',
  500: 'hsl(0, 0%, 96%)',
  700: 'hsl(0, 0%, 95%)',
  900: 'hsl(0, 0%, 93%)',
};

const BASE_COLORS = {
  black: {
    500: 'hsl(203, 7%, 22%)',
    700: 'hsl(0,0%,13%)',
    900: 'hsl(0,0%,0%)',
    '700/60': 'hsl(0,0%,13%, 0.6)',
    '700/80': 'hsl(0,0%,13%, 0.8)',
    '700/90': 'hsl(0,0%,13%, 0.9)',
  },
  transparentBlack: 'hsl(0, 0%, 0%, 0.3)',
  white: {
    500: 'hsl(0, 0%, 100%)',
    '500/20': 'hsl(0, 0%, 100%, 0.2)',
  },
  transparentWhite: 'hsl(0, 0%, 100%, 0.3)',
};

const STOP_LIGHT_GREEN_COLORS = {
  500: 'hsl(163, 39%, 47%)',
  700: 'hsl(162, 39%, 40%)',
};

const STOP_LIGHT_YELLOW_COLORS = {
  500: 'hsl(33, 86%, 65%)',
  700: 'hsl(33, 86%, 55%)',
};

export const COLORS = {
  primary: PRIMARY_COLORS,
  secondary: SECONDARY_COLORS,
  grays: GRAYS,
  base: BASE_COLORS,
  offWhite: OFF_WHITE_COLORS,
  stopLightGreen: STOP_LIGHT_GREEN_COLORS,
  stopLightYellow: STOP_LIGHT_YELLOW_COLORS,
};

// todo - these should not be used anywhere in the app. can replace with css vars or theme context variable
export const PRIMARY = PRIMARY_COLORS[500];
export const LIGHT_PRIMARY = PRIMARY_COLORS[100];
export const SECONDARY = SECONDARY_COLORS[500];
export const DARK_BLUE = PRIMARY_COLORS[900];
export const LIGHT_SECONDARY = SECONDARY_COLORS[100];

const TEXT_COLOR = 'rgba(0,0,0,0.95)';

// default theme for material ui styles
export const muiTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: PRIMARY,
    },
    secondary: { main: SECONDARY },
    text: { primary: TEXT_COLOR },
  },
  typography: {
    fontFamily: 'Roboto',
    fontSize: 15.5,
  },
});

export const BREAKPOINTS = {
  phoneMax: 750,
  tabletMax: 1100,
  laptopMax: 1500,
};

const QUERIES = {
  phoneAndDown: `(max-width: ${BREAKPOINTS.phoneMax / 16}rem)`,
  tabletAndDown: `(max-width: ${BREAKPOINTS.tabletMax / 16}rem)`,
  laptopAndDown: `(max-width: ${BREAKPOINTS.laptopMax / 16}rem)`,
};

// default theme for styled-components ThemeProvider
export const theme = {
  background: BASE_COLORS.white,
  text: {
    primary: TEXT_COLOR,
    secondary: '#585f63',
    dark: '#1d293f',
  },
  main: {
    primary: PRIMARY,
    lightPrimary: LIGHT_PRIMARY,
    secondary: SECONDARY,
    lightSecondary: LIGHT_SECONDARY,
    dark: BASE_COLORS.black,
  },
  grays: GRAYS,
  queries: QUERIES,
  rgba: addPassThrough,
};
